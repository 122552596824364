<template>
  <div class="report-wrapper">
    <el-tabs
      v-model="activeName"
      type="card"
      class="fm-tabs-card"
      :class="tabs[activeName].className"
    >
      <el-tab-pane
        v-for="(item, index) in tabs"
        :key="item.name"
        :label="item.name"
        :name="`${index}`"
      >
        <span slot="label">
          <i v-if="item.iconName" :class="item.iconName" class="mr2"></i>
          {{ item.name }}
        </span>
        <component
          :is="item.component"
          :tab="index"
          :show="activeName == index"
          @orderNumberClick="onOrderNumberClick"
        ></component>
      </el-tab-pane>
    </el-tabs>
    <c-report-detail
      :visible.sync="reportDetailVisible"
      :report-id="activeReportId"
    ></c-report-detail>
  </div>
</template>

<script>
import CPanel from "./_components/panel";
import CReportDetail from "./_components/reportDetail";
import CStatistics from "./_components/statistics";
export default {
  components: {
    CPanel,
    CReportDetail,
    CStatistics
  },
  data() {
    return {
      activeName: "0",
      tabs: [
        {
          name: "全部",
          component: "CPanel"
        },
        {
          name: "处理中",
          component: "CPanel"
        },
        {
          name: "已带看",
          component: "CPanel"
        },
        {
          name: "未带看",
          component: "CPanel"
        },
        {
          name: "统计",
          iconName: "iconfont icon-statistics",
          component: "CStatistics",
          className: "report-statistics"
        }
      ],
      reportDetailVisible: false,
      activeReportId: null
    };
  },
  created() {},
  methods: {
    // 点击单号 展开详情
    onOrderNumberClick(item) {
      this.reportDetailVisible = true;
      this.activeReportId = item.id;
    }
  }
};
</script>

<style scoped lang="scss">
.report-wrapper {
  height: 100%;
  ::v-deep {
    .fm-tabs-card:not(.report-statistics) {
      .el-tabs__content {
        padding: 0;
        height: calc(100% - 20px);
        .el-tab-pane {
          overflow-y: hidden;
          height: 100%;
        }
      }
    }
  }
}
.report-statistics {
  ::v-deep {
    .el-tabs__content {
      padding: 0;
      height: calc(100% - 40px);
    }
  }
  .el-tab-pane {
    overflow-y: hidden;
    height: 100%;
    ::v-deep {
      .el-tabs--left .el-tabs__header.is-left {
        width: 205px;
        margin-right: 0;
      }
      .el-tabs__content {
        height: 100%;
        overflow-y: auto;
        border-radius: 0;
        padding: 20px;
      }
      .el-tabs__item {
        border: 0;
        height: 48px;
        line-height: 48px;
        padding-left: 35px;
        border-radius: 4px 0 0 4px;
        color: #9399a5;
        font-size: 15px;
        &.is-active {
          color: #333;
          background: #fff;
          font-weight: bold;
        }
        &:nth-child(2) {
          padding-left: 35px;
        }
      }
      .el-tabs--left.el-tabs--card .el-tabs__nav {
        padding: 60px 0 0 30px;
        border-bottom: 0;
      }
    }
  }
}
</style>
