<template>
  <div class="customer-determine">
    <div class="filter-bar">
      <div class="label">
        带看判定
      </div>
      <div>
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          align="right"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          unlink-panels
          :picker-options="pickerOptions"
          @change="onDateRangeChange"
          class="mr10"
          size="medium"
        >
        </el-date-picker>
        <el-select
          class="select"
          v-model="queryData.estateId"
          filterable
          clearable
          placeholder="报备项目"
          @change="fetchData"
          size="medium"
        >
          <el-option
            :value="item.id"
            :label="item.name"
            v-for="(item, index) in estateList"
            :key="index"
          ></el-option>
        </el-select>
      </div>
    </div>
    <el-table
      :data="tableData"
      :span-method="objectSpanMethod"
      border
      style="width: 100%"
      class="mt15"
    >
      <el-table-column label="判定类别" align="center" width="135">
        <template slot-scope="{ row }">
          {{ row.label }}
        </template>
      </el-table-column>
      <el-table-column label="类型" prop="name" align="center">
      </el-table-column>
      <el-table-column label="数量" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.count">{{ row.count }}组</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="占比" width="220" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.proportion && row.proportion != 0">{{
            row.proportion
          }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
// pcn平台的客户判定标签
const options1 = [
  {
    name: "A",
    label: "客户判定"
  },
  {
    name: "B",
    label: "客户判定"
  },
  {
    name: "C",
    label: "客户判定"
  },
  {
    name: "D",
    label: "客户判定"
  },
  {
    name: "D+",
    label: "客户判定"
  }
];
// 其它平台的客户判定标签
const options2 = [
  {
    name: "一星(普通)",
    label: "客户判定"
  },
  {
    name: "二星(优质)",
    label: "客户判定"
  },
  {
    name: "三星(精准)",
    label: "客户判定"
  },
  {
    name: "无效客户",
    label: "客户判定"
  }
];
// 公用字段
const options3 = [
  {
    name: "首看",
    label: "带看次数"
  },
  {
    name: "2看",
    label: "带看次数"
  },
  {
    name: "2看+",
    label: "带看次数"
  },
  {
    name: "有效带看",
    label: "带看判定"
  },
  {
    name: "无效带看",
    label: "带看判定"
  }
];
import { moment } from "@/utils";
import { datePickerShortcuts } from "@/utils/component-utils";
import {
  getReportListCondition,
  getCustomerDetermineStatistics
} from "@/api/distribution";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: datePickerShortcuts()
      },
      queryData: {
        startTime: moment()
          .startOf("week")
          .format("YYYY-MM-DD"),
        endTime: moment()
          .endOf("week")
          .format("YYYY-MM-DD"),
        estateId: "",
        size: 10
      },
      dateRange: [
        moment()
          .startOf("week")
          .format("YYYY-MM-DD"),
        moment()
          .endOf("week")
          .format("YYYY-MM-DD")
      ],
      estateList: [],
      tableData: []
    };
  },
  computed: {
    platformId() {
      return this.$store.state.platformInfo.platformId;
    },
    list() {
      if (this.platformId == 34) {
        return [...options1, ...options3];
      } else {
        return [...options2, ...options3];
      }
    }
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.getReportListCondition();
            this.fetchData();
          });
        }
      }
    }
  },
  methods: {
    // 筛选条件
    async getReportListCondition() {
      const res = await getReportListCondition({
        platformIds: [this.$store.state.platformInfo.platformId]
      });
      this.estateList = res?.estates || [];
    },
    async fetchData() {
      const res = await getCustomerDetermineStatistics(this.queryData);
      const objectMap = (res || []).reduce((map, obj) => {
        map.set(obj.name, obj);
        return map;
      }, new Map());

      this.tableData = this.list.map(item => {
        const obj = objectMap.get(item.name);
        return {
          ...item,
          ...obj
        };
      });
    },
    onDateRangeChange(e) {
      const [startTime, endTime] = e || [];
      Object.assign(this.queryData, { startTime, endTime });
      this.fetchData();
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      // 客户判定需要合并的单元格数  pcn是5个，其它平台是4个
      const rowsLength = this.platformId == 34 ? 5 : 4;
      if (columnIndex === 0) {
        if (rowIndex === 0) {
          return { rowspan: rowsLength, colspan: 1 };
        } else if (rowIndex === rowsLength) {
          return { rowspan: 3, colspan: 1 };
        } else if (rowIndex === rowsLength + 3) {
          return { rowspan: 2, colspan: 1 };
        } else {
          return { rowspan: 0, colspan: 0 };
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.filter-bar {
  @include flex-ycb;
  .label {
    font-size: 18px;
    font-weight: bold;
  }
}
.customer-determine {
  padding-top: 10px;
}
</style>
