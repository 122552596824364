<template>
  <el-tabs
    v-model="activeName"
    tab-position="left"
    type="card"
    class="statistics"
  >
    <el-tab-pane
      v-for="(item, index) in tabs"
      :key="item.name"
      :label="item.name"
      :name="`${index}`"
    >
      <span slot="label">
        <i class="iconfont mr5" :class="item.icon"></i>
        {{ item.name }}
      </span>
      <component
        :is="item.component"
        :show="show && activeName == index"
      ></component>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import CChannelDistribution from "./_components/channelDistribution";
import CCustomerDetermine from "./_components/customerDetermine";
import CChannelFollow from "./_components/channelFollow";
import CCalendar from "./_components/calendar";
export default {
  components: {
    CChannelDistribution,
    CCustomerDetermine,
    CChannelFollow,
    CCalendar
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeName: "0",
      tabs: [
        {
          name: "渠道分布",
          component: "CChannelDistribution",
          icon: "icon-channel-rank"
        },
        {
          name: "带看判定",
          component: "CCustomerDetermine",
          icon: "icon-determine"
        },
        {
          name: "量化日历",
          component: "CCalendar",
          icon: "icon-calendar"
        },
        {
          name: "渠道跟进",
          component: "CChannelFollow",
          icon: "icon-record"
        }
      ]
    };
  },
  created() {},
  methods: {}
};
</script>

<style scoped lang="scss">
.statistics {
  height: 100%;
  background: rgba(233, 237, 241, 1);
}
</style>
