<template>
  <div class="channel-follow">
    <div class="filter-bar">
      <el-radio-group
        v-model="queryData.type"
        @change="fetchData"
        size="medium"
      >
        <el-radio-button label="1">人员分布</el-radio-button>
        <el-radio-button label="2">渠道分布</el-radio-button>
      </el-radio-group>
      <div>
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          align="right"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          unlink-panels
          :picker-options="pickerOptions"
          @change="onDateRangeChange"
          class="mr10"
          size="medium"
        >
        </el-date-picker>
        <!-- <el-select
          class="select"
          v-model="queryData.estateId"
          filterable
          clearable
          placeholder="报备项目"
          @change="fetchData"
          size="medium"
        >
          <el-option
            :value="item.id"
            :label="item.name"
            v-for="(item, index) in estateList"
            :key="index"
          ></el-option>
        </el-select> -->
      </div>
    </div>
    <el-table :data="tableData" border style="width: 100%" class="mt15">
      <el-table-column type="index" label="序号" align="center" width="65">
      </el-table-column>
      <el-table-column prop="name" label="渠道名称"> </el-table-column>
      <el-table-column
        prop="visitCount"
        label="拜访"
        align="center"
        width="190"
      >
        <template v-slot="{ row }">{{ row.visitCount || "-" }}</template>
      </el-table-column>
      <el-table-column prop="cultivate" label="培训" align="center" width="190">
        <template v-slot="{ row }">{{ row.cultivate || "-" }}</template>
      </el-table-column>
      <el-table-column prop="other" label="其他" align="center" width="190">
        <template v-slot="{ row }">{{ row.other || "-" }}</template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { moment } from "@/utils";
import { datePickerShortcuts } from "@/utils/component-utils";

import {
  getReportListCondition,
  getMechanismFollowStatistics,
  getMechanismUserFollowStatistics
} from "@/api/distribution";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: datePickerShortcuts()
      },
      queryData: {
        dateStart: moment()
          .startOf("week")
          .format("YYYY-MM-DD"),
        dateEnd: moment()
          .endOf("week")
          .format("YYYY-MM-DD"),
        estateId: "",
        size: 10,
        type: 1 // 1 人员分布 2 渠道分布
      },
      dateRange: [
        moment()
          .startOf("week")
          .format("YYYY-MM-DD"),
        moment()
          .endOf("week")
          .format("YYYY-MM-DD")
      ],
      estateList: [],
      tableData: []
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.getReportListCondition();
            this.fetchData();
          });
        }
      }
    }
  },
  methods: {
    // 筛选条件
    async getReportListCondition() {
      const res = await getReportListCondition({
        platformIds: [this.$store.state.platformInfo.platformId]
      });
      this.estateList = res?.estates || [];
    },
    // 渠道跟进统计-渠道
    async getMechanismFollowStatistics() {
      const res = await getMechanismFollowStatistics(this.queryData);
      this.tableData = res || [];
    },
    // 渠道跟进统计-用户
    async getMechanismUserFollowStatistics() {
      const res = await getMechanismUserFollowStatistics(this.queryData);
      this.tableData = res || [];
    },
    async fetchData() {
      this.queryData.type == 1
        ? this.getMechanismUserFollowStatistics()
        : this.getMechanismFollowStatistics();
    },
    onDateRangeChange(e) {
      const [dateStart, dateEnd] = e || [];
      Object.assign(this.queryData, { dateStart, dateEnd });
      this.fetchData();
    }
  }
};
</script>

<style scoped lang="scss">
.filter-bar {
  @include flex-ycb;
}
.channel-follow {
  padding-top: 10px;
}
</style>
