var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "report-wrapper"
  }, [_c('el-tabs', {
    staticClass: "fm-tabs-card",
    class: _vm.tabs[_vm.activeName].className,
    attrs: {
      "type": "card"
    },
    model: {
      value: _vm.activeName,
      callback: function callback($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, _vm._l(_vm.tabs, function (item, index) {
    return _c('el-tab-pane', {
      key: item.name,
      attrs: {
        "label": item.name,
        "name": "".concat(index)
      }
    }, [_c('span', {
      attrs: {
        "slot": "label"
      },
      slot: "label"
    }, [item.iconName ? _c('i', {
      staticClass: "mr2",
      class: item.iconName
    }) : _vm._e(), _vm._v(" " + _vm._s(item.name) + " ")]), _c(item.component, {
      tag: "component",
      attrs: {
        "tab": index,
        "show": _vm.activeName == index
      },
      on: {
        "orderNumberClick": _vm.onOrderNumberClick
      }
    })], 1);
  }), 1), _c('c-report-detail', {
    attrs: {
      "visible": _vm.reportDetailVisible,
      "report-id": _vm.activeReportId
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.reportDetailVisible = $event;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }