var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-tabs', {
    staticClass: "statistics",
    attrs: {
      "tab-position": "left",
      "type": "card"
    },
    model: {
      value: _vm.activeName,
      callback: function callback($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, _vm._l(_vm.tabs, function (item, index) {
    return _c('el-tab-pane', {
      key: item.name,
      attrs: {
        "label": item.name,
        "name": "".concat(index)
      }
    }, [_c('span', {
      attrs: {
        "slot": "label"
      },
      slot: "label"
    }, [_c('i', {
      staticClass: "iconfont mr5",
      class: item.icon
    }), _vm._v(" " + _vm._s(item.name) + " ")]), _c(item.component, {
      tag: "component",
      attrs: {
        "show": _vm.show && _vm.activeName == index
      }
    })], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }