<template>
  <div class="channel-distribution">
    <div class="filter-bar">
      <el-radio-group
        v-model="queryData.reportState"
        @change="fetchData"
        size="medium"
      >
        <el-radio-button label="1">报备排行</el-radio-button>
        <el-radio-button label="2">带看排行</el-radio-button>
      </el-radio-group>
      <div>
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          align="right"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          unlink-panels
          :picker-options="pickerOptions"
          @change="onDateRangeChange"
          class="mr10"
          size="medium"
        >
        </el-date-picker>
        <el-select
          class="select"
          v-model="queryData.estateId"
          filterable
          clearable
          placeholder="报备项目"
          @change="fetchData"
          size="medium"
        >
          <el-option
            :value="item.id"
            :label="item.name"
            v-for="(item, index) in estateList"
            :key="index"
          ></el-option>
        </el-select>
      </div>
    </div>
    <el-table :data="tableData" border style="width: 100%" class="mt15">
      <el-table-column type="index" label="序号" align="center" width="65">
      </el-table-column>
      <el-table-column prop="name" label="渠道名称"> </el-table-column>
      <el-table-column prop="count" label="数量" align="center" width="200">
        <template v-slot="{ row }"
          ><span v-if="row.count"> {{ row.count }}组</span><span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="占比" align="center" width="200">
        <template v-slot="{ row }">
          <span> {{ row.proportion || "-" }}</span></template
        >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { moment } from "@/utils";
import { datePickerShortcuts } from "@/utils/component-utils";
import {
  getReportListCondition,
  getReportRankListByChannel
} from "@/api/distribution";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: datePickerShortcuts()
      },
      queryData: {
        startTime: moment()
          .startOf("week")
          .format("YYYY-MM-DD"),
        endTime: moment()
          .endOf("week")
          .format("YYYY-MM-DD"),
        estateId: "",
        size: 15,
        reportState: 1 // 1 新增报备 2 已带看
      },
      dateRange: [
        moment()
          .startOf("week")
          .format("YYYY-MM-DD"),
        moment()
          .endOf("week")
          .format("YYYY-MM-DD")
      ],
      estateList: [],
      tableData: []
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.getReportListCondition();
            this.fetchData();
          });
        }
      }
    }
  },
  methods: {
    // 筛选条件
    async getReportListCondition() {
      const res = await getReportListCondition({
        platformIds: [this.$store.state.platformInfo.platformId]
      });
      this.estateList = res?.estates || [];
    },
    async fetchData() {
      const res = await getReportRankListByChannel(this.queryData);
      this.tableData = res || [];
    },
    onDateRangeChange(e) {
      const [startTime, endTime] = e || [];
      Object.assign(this.queryData, { startTime, endTime });
      this.fetchData();
    }
  }
};
</script>

<style scoped lang="scss">
.filter-bar {
  @include flex-ycb;
}
.channel-distribution {
  padding-top: 10px;
}
</style>
