var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "channel-follow"
  }, [_c('div', {
    staticClass: "filter-bar"
  }, [_c('el-radio-group', {
    attrs: {
      "size": "medium"
    },
    on: {
      "change": _vm.fetchData
    },
    model: {
      value: _vm.queryData.type,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "type", $$v);
      },
      expression: "queryData.type"
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": "1"
    }
  }, [_vm._v("人员分布")]), _c('el-radio-button', {
    attrs: {
      "label": "2"
    }
  }, [_vm._v("渠道分布")])], 1), _c('div', [_c('el-date-picker', {
    staticClass: "mr10",
    attrs: {
      "type": "daterange",
      "align": "right",
      "range-separator": "至",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      "format": "yyyy-MM-dd",
      "value-format": "yyyy-MM-dd",
      "unlink-panels": "",
      "picker-options": _vm.pickerOptions,
      "size": "medium"
    },
    on: {
      "change": _vm.onDateRangeChange
    },
    model: {
      value: _vm.dateRange,
      callback: function callback($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  })], 1)], 1), _c('el-table', {
    staticClass: "mt15",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "data": _vm.tableData,
      "border": ""
    }
  }, [_c('el-table-column', {
    attrs: {
      "type": "index",
      "label": "序号",
      "align": "center",
      "width": "65"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "name",
      "label": "渠道名称"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "visitCount",
      "label": "拜访",
      "align": "center",
      "width": "190"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(_vm._s(row.visitCount || "-"))];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "cultivate",
      "label": "培训",
      "align": "center",
      "width": "190"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(_vm._s(row.cultivate || "-"))];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "other",
      "label": "其他",
      "align": "center",
      "width": "190"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(_vm._s(row.other || "-"))];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }