<template>
  <div class="customer-determine">
    <div class="filter-bar">
      <div class="action">
        <el-button icon="el-icon-arrow-left" @click="clickLast"></el-button>
        <!-- {{ moment(value).format("YYYY年M月") }} -->
        <el-date-picker
          v-model="value"
          type="month"
          style="width: 90px;"
          value-format="yyyy-MM"
          format="yyyy年M月"
          placeholder="选择月"
          prefix-icon=""
          :clearable="false"
          :editable="false"
          :picker-options="setDateRange"
        ></el-date-picker>
        <el-button
          icon="el-icon-arrow-right"
          @click="clickNext"
          :disabled="isDisabledNext"
        ></el-button>
      </div>
      <el-select
        class="select"
        v-model="queryData.estateId"
        filterable
        clearable
        placeholder="报备项目"
        @change="fetchData"
        size="medium"
      >
        <el-option
          :value="item.id"
          :label="item.name"
          v-for="(item, index) in estateList"
          :key="index"
        ></el-option>
      </el-select>
    </div>
    <el-calendar :value="value" ref="domCalendarBtn">
      <template v-slot:dateCell="{ data: { day } }">
        <div class="cell">
          <div class="label">{{ formatDate(day) }}</div>
          <div class="today">今</div>
          <div class="statistics" v-if="moment(day).isSameOrBefore(moment())">
            <div
              class="item"
              :class="{
                disabled: !list[day] || !list[day].visitCount
              }"
            >
              <span>带看</span
              ><span class="num" v-if="list[day] && list[day].visitCount"
                >+{{ list[day].visitCount }} </span
              ><span v-else>-</span>
            </div>
            <div
              class="item primary"
              :class="{
                disabled: !list[day] || !list[day].reportCount
              }"
            >
              <span>报备</span
              ><span class="num" v-if="list[day] && list[day].reportCount"
                >+{{ list[day].reportCount }} </span
              ><span v-else>-</span>
            </div>
          </div>
        </div>
      </template>
    </el-calendar>
    <div class="summary">
      {{ moment(value).format("YYYY年M月") }}合计：报备
      <span class="num">{{ reportCount }}组</span>， 带看
      <span class="num">{{ visitCount }}组</span>
    </div>
  </div>
</template>

<script>
import { moment } from "@/utils";
import { getReportListCondition, reportCalendar } from "@/api/distribution";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: moment().format("YYYY-MM-DD"),
      queryData: {
        startTime: moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        endTime: moment()
          .endOf("month")
          .format("YYYY-MM-DD"),
        estateId: ""
      },
      setDateRange: {
        disabledDate: time => {
          // 禁用本月之后的月份
          return moment(time).isAfter(moment(), "month");
        }
      },
      estateList: [],
      list: {},
      visitCount: 0,
      reportCount: 0
    };
  },
  computed: {
    isDisabledNext() {
      return moment(this.value).isSameOrAfter(moment(), "month");
    }
  },
  watch: {
    value(val) {
      this.queryData.date = val;
      this.queryData.startTime = moment(val)
        .startOf("month")
        .format("YYYY-MM-DD");
      this.queryData.endTime = moment(val)
        .endOf("month")
        .format("YYYY-MM-DD");
      this.fetchData();
    },
    show: {
      immediate: true,
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.getReportListCondition();
            this.fetchData();
          });
        }
      }
    }
  },
  methods: {
    moment,
    formatDate(date) {
      // 是否是每月第一天
      const isFisrtDayOfMonth = moment(date).isSame(
        moment(date).startOf("month"),
        "day"
      );
      return isFisrtDayOfMonth
        ? moment(date).format("M月D日")
        : moment(date).format("D日");
    },
    //上个月
    clickLast() {
      this.value = moment(this.value)
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
    },
    //下个月
    clickNext() {
      this.value = moment(this.value)
        .add(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
    },
    // 获取报备带看 日历
    async fetchData() {
      this.reportCount = 0;
      this.visitCount = 0;
      const res = await reportCalendar(this.queryData);
      if (res) {
        this.list = res.reduce((acc, cur) => {
          acc[cur.day] = cur;
          return acc;
        }, {});
        res.forEach(item => {
          this.reportCount += item.reportCount || 0;
          this.visitCount += item.visitCount || 0;
        });
        console.log(this.reportCount, this.visitCount);
      }
    },
    // 筛选条件
    async getReportListCondition() {
      const res = await getReportListCondition({
        platformIds: [this.$store.state.platformInfo.platformId]
      });
      this.estateList = res?.estates || [];
    }
  }
};
</script>

<style scoped lang="scss">
.filter-bar {
  @include flex-ycb;
  margin-bottom: 15px;
  ::v-deep {
    .action {
      @include flex-yc;
      font-size: 16px;
      font-weight: bold;
      height: 30px;
      line-height: 30px;
    }
    .el-button {
      padding: 0;
      width: 30px;
      height: 30px;
      background: rgb(249, 249, 249);
      border: none;
      font-size: 20px;
      font-weight: bold;
      margin-right: 10px;
      @include flex-xyc;
    }
    .el-date-editor {
      .el-input__prefix {
        display: none;
      }
    }
    .el-input__inner {
      color: #333;
    }
    .el-input--prefix .el-input__inner {
      padding: 0;
      border: none;
      height: 24px;
      line-height: 24px;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
::v-deep {
  .el-calendar-table .el-calendar-day:hover {
    background-color: #fbfcff;
  }
  .el-calendar-table:not(.is-range) {
    // 下个月的日期有时候多一行，用css隐藏掉
    td.next {
      display: none;
    }
    td.current ~ td.next {
      display: table-cell;
    }
  }

  .el-calendar__header {
    display: none;
  }
  .el-calendar__body {
    padding: 0;
  }
  .el-calendar-day {
    height: 130px;
    padding: 5px 8px;
  }

  .el-calendar-table thead {
    th {
      background-color: #fafafa;
      border-right: 1px solid #ebeef5;
      border-top: 1px solid #ebeef5;
      &:first-child {
        border-left: 1px solid #ebeef5;
      }
      &::before {
        content: "周";
      }
    }
  }
  .el-calendar-table td.is-selected {
    background: none;
  }
}
.cell {
  .label {
    font-weight: bold;
    margin-bottom: 12px;
    height: 28px;
    line-height: 28px;
  }
  .today {
    width: 28px;
    height: 28px;
    background: rgb(217, 0, 27);
    border-radius: 50%;
    color: #fff;
    display: none;
    margin-bottom: 12px;
  }
  .item {
    @include flex-ycb;
    padding: 0 5px 0 8px;
    height: 35px;
    background-color: rgba(236, 128, 141, 0.05);
    border-left: 2px solid rgba(217, 0, 27, 1);
    font-size: 14px;
    color: rgba(58, 66, 76, 0.8);
    .num {
      font-size: 17px;
      color: rgb(217, 0, 27);
    }
    &.primary {
      background-color: rgba(128, 128, 255, 0.05);
      border-left: 2px solid rgba(45, 151, 251, 1);
      .num {
        color: rgb(45, 151, 251);
      }
    }
    &.disabled {
      background-color: rgba(147, 153, 165, 0.05);
      border-color: rgba(210, 214, 223, 1);
      color: #aaa;
    }
  }
  .item + .item {
    margin-top: 6px;
  }
}
.is-today {
  .today {
    @include flex-xyc;
  }
  .label {
    display: none;
  }
}
.prev,
.next {
  .statistics {
    display: none;
  }
}
.summary {
  text-align: right;
  margin-top: 15px;
  color: #aaa;
  .num {
    color: #333;
  }
}
.customer-determine {
  padding-top: 10px;
}
</style>
