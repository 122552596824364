import { moment } from "./index";

export const datePickerShortcuts = () => {
  const result = [
    {
      text: "本周",
      onClick(picker) {
        const startOfWeek = moment()
          .startOf("week")
          .toDate();
        const endOfWeek = moment().toDate();
        picker.$emit("pick", [startOfWeek, endOfWeek]);
      }
    },
    {
      text: "上周",
      onClick(picker) {
        const startOfLastWeek = moment()
          .subtract(1, "weeks")
          .startOf("week")
          .toDate();
        const endOfLastWeek = moment()
          .subtract(1, "weeks")
          .endOf("week")
          .toDate();
        picker.$emit("pick", [startOfLastWeek, endOfLastWeek]);
      }
    },
    {
      text: "本月",
      onClick(picker) {
        const startOfMonth = moment()
          .startOf("month")
          .toDate();
        const endOfMonth = moment().toDate();
        picker.$emit("pick", [startOfMonth, endOfMonth]);
      }
    },
    {
      text: "上月",
      onClick(picker) {
        const startOfLastMonth = moment()
          .subtract(1, "months")
          .startOf("month")
          .toDate();
        const endOfLastMonth = moment()
          .subtract(1, "months")
          .endOf("month")
          .toDate();
        picker.$emit("pick", [startOfLastMonth, endOfLastMonth]);
      }
    }
  ];
  return result;
};
